<template>
  <v-container fluid v-resize="innerCal">
    <v-row>
      <v-col class="pa-1">
        <DxDataGrid
          id="gridContainer"
          :data-source="store"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :showColumnLines="true"
          :show-borders="true"
          :height="inheight - 58"
          column-resizing-mode="widget"
        >
          <!-- :column-auto-width="true" -->
          <DxLoadPanel :enabled="true" />
          <DxPaging :page-size="100" />
          <DxExport
            :enabled="true"
            :allow-export-selected-data="false"
            :texts="{
              exportAll: 'ส่งออกข้อมูลเป็น Excel',
            }"
          />
          <DxEditing
            :allow-updating="true"
            :allow-adding="false"
            :allow-deleting="false"
            mode="batch"
          />
          <DxFilterRow :visible="true" />
          <DxSorting
            mode="multiple"
            ascendingText="เรียงจากน้อยไปมาก"
            clearText="ยกเลิกการจัดเรียง"
            descendingText="เรียงจากมากไปน้อย"
          />
          <DxHeaderFilter
            :visible="true"
            :texts="{
              ok: 'ตกลง',
              cancel: 'ยกเลิก',
              emptyValue: 'ค่าว่าง',
            }"
          />
          <DxGrouping
            :context-menu-enabled="true"
            :auto-expand-all="autoExpandAll"
            :texts="{
              groupByThisColumn: 'ใช้คอลัมน์นี้เพื่อจัดกลุ่ม',
              ungroup: 'ยกเลิกการจัดกลุ่ม (คอลัมน์นี้)',
              ungroupAll: 'ยกเลิกการจัดกลุ่ม (คอลัมน์ทั้งหมด)',
            }"
          />
          <DxGroupPanel
            :visible="true"
            emptyPanelText="ลากคอลัมน์มาวางที่นี่เพื่อจัดกลุ่ม"
          />
          <DxColumnFixing :enabled="true" />
          <!-- <DxScrolling mode="virtual" /> -->
          <DxScrolling
            mode="standard"
            showScrollbar="always"
            :useNative="true"
          />
          <DxSearchPanel :visible="true" :width="300" placeholder="ค้นหา..." />
          <DxColumn data-field="id" :visible="false" />
          <DxColumn data-field="jsjid" caption="รหัส" width="80" />
          <DxColumn data-field="pname" caption="คำนำ" width="80" />
          <DxColumn data-field="fname" caption="ชื่อ" width="100" />
          <DxColumn data-field="lname" caption="สกุล" width="100" />
          <DxColumn data-field="year_of_birth" caption="ปีเกิด" width="70" />
          <DxColumn data-field="phone_number" caption="โทร" width="120" />
          <DxColumn data-field="line" caption="ไลน์" width="150" />
          <DxColumn data-field="class" caption="หลักสูตร" width="70" />
          <DxColumn data-field="room" caption="ห้อง" width="70" />
          <DxColumn data-field="occupation" caption="อาชีพ" width="120" />
          <DxColumn
            data-field="know_from"
            caption="รู้จักมูลนิธิโดย"
            width="150"
          />
          <DxColumn
            data-field="room1"
            caption="เข้ามูลนิธิ"
            data-type="date"
            width="110"
          />
          <DxColumn
            data-field="room2"
            caption="หลักสูตร 2"
            data-type="date"
            width="110"
          />
          <DxColumn
            data-field="room3"
            caption="หลักสูตร 3"
            data-type="date"
            width="110"
          />
          <DxColumn
            data-field="room4"
            caption="หลักสูตร 4"
            data-type="date"
            width="110"
          />
          <DxColumn
            data-field="room5"
            caption="หลักสูตร 5"
            data-type="date"
            width="110"
          />
          <DxColumn
            data-field="room6"
            caption="หลักสูตร 6"
            data-type="date"
            width="110"
          />
          <DxColumn
            data-field="room7"
            caption="หลักสูตร 7"
            data-type="date"
            width="110"
          />
          <DxColumn
            data-field="room71"
            caption="หลักสูตร 7.1"
            data-type="date"
            width="110"
          />
          <DxColumn
            data-field="room8"
            caption="หลักสูตร 8"
            data-type="date"
            width="110"
          />
          <DxColumn
            data-field="room9"
            caption="หลักสูตร 9"
            data-type="date"
            width="110"
          />
          <DxColumn
            data-field="room10"
            caption="หลักสูตร 10"
            data-type="date"
            width="110"
          />
          <DxColumn data-field="teacher1" caption="อาจารย์1" width="150" />
          <DxColumn data-field="teacher2" caption="อาจารย์2" width="150" />
          <DxColumn data-field="province" caption="จังหวัด" width="150" />
          <DxColumn data-field="status" caption="สถานะ" width="100" />
          <DxColumn data-field="remark" caption="remark" width="300" />
          <DxColumn data-field="randkey" caption="randkey" width="300" />
          
          <DxSummary>
            <DxGroupItem
              column="class"
              summary-type="count"
              display-format="{0} ท่าน"
            />
            <!-- <DxGroupItem
              :show-in-group-footer="true"
              column="total"
              summary-type="sum"
              :value-format="numberFormat"
              display-format="รวม : {0}"
            /> -->
            <DxTotalItem
              column="jsjid"
              summary-type="count"
              :customizeText="customizeText"
            />
          </DxSummary>
        </DxDataGrid>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxColumnFixing,
  DxScrolling,
  DxFilterRow,
  DxHeaderFilter,
  DxGrouping,
  DxGroupPanel,
  DxSummary,
  DxGroupItem,
  DxSearchPanel,
  DxSorting,
  DxEditing,
  DxExport,
  DxLoadPanel,
  DxPaging,
  DxTotalItem,
} from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { locale } from "devextreme/localization";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxScrolling,
    DxFilterRow,
    DxHeaderFilter,
    DxGrouping,
    DxGroupPanel,
    DxSummary,
    DxGroupItem,
    DxSearchPanel,
    DxSorting,
    DxEditing,
    DxExport,
    DxLoadPanel,
    DxPaging,
    DxTotalItem,
  },
  data: () => ({
    store: [],
    inheight: 0,
    autoExpandAll: false,
  }),
  methods: {
    customizeText(data) {
      return "รวม : " + data.value;
    },
    setStore() {
      this.store = new CustomStore({
        key: "id",
        load: () => {
          return this.axios
            .get(`${this.$store.state.apiurl}/api/gridcrudread`, {
              params: {},
              headers: {
                "Content-type": "application/json",
              },
            })
            .then((res) => {
              return res.data;
            });
        },
      });
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "DataGrid.xlsx"
          );
        });
      });
      e.cancel = true;
    },
    innerCal() {
      this.inheight = window.innerHeight;
    },
  },
  computed: {},
  mounted() {
    this.setStore();
    locale("th");
  },
};
</script>

<style>
/* #gridContainer .dx-datagrid {
  font: 9px verdana;
} */

.dx-datagrid .dx-row > td {
  padding: 5px 5px;
  font-size: 15px;
  /* line-height: 16px; */
}

/* .dx-editor-cell .dx-texteditor .dx-texteditor-input {
  background: rgba(255, 255, 255, 0);
  font-size: 15px;
  height: 16px;
  line-height: 16px;
  padding: 5px 5px;
  margin-top: 0;
} */
</style>